<template>
  <div>
    <h1>Coming soon...</h1>
    <h2>^_^</h2>
    <router-link to="/">Back</router-link>
  </div>
</template>

<script>
export default {
  name: 'Blog'
};
</script>

<style scoped>

</style>
